import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'btnEmailYes',
    'btnEmailNo',
    'divEmailYes',
    'divEmailNo',
    'btnRelationshipRetired',
    'btnRelationshipVolunteer',
    'divRelationshipRetired',
    'divRelationshipVolunteer',
  ]

  initialize() {
    this.btnEmailSelection()
  }

  btnEmailSelection() {
    this.#showDivIfTrue(this.btnEmailYesTarget.checked, this.divEmailYesTarget)
    this.#showDivIfTrue(this.btnEmailNoTarget.checked, this.divEmailNoTarget)

    this.btnRelationshipRetiredTarget.checked = false
    this.btnRelationshipVolunteerTarget.checked = false
    this.btnRelationshipSelection()
  }

  btnRelationshipSelection() {
    this.#showDivIfTrue(this.btnRelationshipRetiredTarget.checked, this.divRelationshipRetiredTarget)
    this.#showDivIfTrue(this.btnRelationshipVolunteerTarget.checked, this.divRelationshipVolunteerTarget)
  }

  #showDivIfTrue(boolean, div) {
    if (boolean) {
      div.classList.remove('d-none')
    } else {
      div.classList.add('d-none')
    }
  }
}
