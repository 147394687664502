import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'employerSelect',
    'freeFormUnionContainer',
    'workEmailConfirmation',
    'fullFormContainer',
    'unnamedUnionContainer',
    'unionMessageContainer',
  ]

  handleSelection() {
    // Hide all containers by default.
    this.fullFormContainerTarget.classList.add('d-none')
    this.unnamedUnionContainerTarget.classList.add('d-none')
    this.unionMessageContainerTarget.classList.add('d-none')

    // If they haven't selected a union, don't show anything.
    const select = this.employerSelectTarget
    const unionValue = select.options[select.selectedIndex].innerHTML
    if (unionValue === '' || unionValue === undefined) {
      return
    }

    const otherUnion = unionValue === 'Other Union';
    const noUnion = unionValue === 'No Union';

    // If they've selected "Other union", show the freeform union field.
    if (otherUnion) {
      this.freeFormUnionContainerTarget.classList.remove('d-none')
      $("#user_employer_extended_info").prop("required", true);
    }
    else {
      this.freeFormUnionContainerTarget.classList.add('d-none')
      $("#user_employer_extended_info").removeAttr("required");
    }

    // If they have selected a named union, show the full form and return.
    if (!(otherUnion || noUnion)) {
      this.fullFormContainerTarget.classList.remove('d-none')
      return
    }

    // If they have not selected a named union, show the work email confirmation selection.
    this.unnamedUnionContainerTarget.classList.remove('d-none')

    // Only show the full form if they've selected "Yes".
    // Otherwise, show the message div.
    this.workEmailConfirmationTargets.forEach(element => {
      if (element.checked) {
        if (element.value === 'Yes') {
          this.fullFormContainerTarget.classList.remove('d-none')
        } else {
          this.unionMessageContainerTarget.classList.remove('d-none')
        }
      }
    })
  }

  initialize() {
    this.handleSelection();
  }
}
