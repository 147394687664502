import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'btnEmailYes',
    'btnEmailNo',
    'divEmailYes',
    'divEmailNo',
    'btnRelationshipStudent',
    'btnRelationshipContractor',
    'btnRelationshipRetired',
    'btnRelationshipVolunteer',
    'divRelationshipStudent',
    'divRelationshipContractor',
    'divRelationshipRetired',
    'divRelationshipVolunteer',
    'btnIAgree',
    'divIAgree',
    'divSubmitEvidence',
  ]

  initialize() {
    this.btnEmailSelection()
  }

  btnEmailSelection() {
    this.#showDivIfTrue(this.btnEmailYesTarget.checked || this.btnIAgreeTarget.checked, this.divEmailYesTarget)
    this.#showDivIfTrue(this.btnEmailNoTarget.checked, this.divEmailNoTarget)

    this.btnRelationshipSelection()

    this.#showDivIfTrue(this.btnEmailNoTarget.checked, this.divSubmitEvidenceTarget)
  }

  btnRelationshipSelection() {
    this.#showDivIfTrue(this.btnRelationshipStudentTarget.checked, this.divRelationshipStudentTarget)
    this.#showDivIfTrue(this.btnRelationshipContractorTarget.checked, this.divRelationshipContractorTarget)
    this.#showDivIfTrue(this.btnRelationshipRetiredTarget.checked, this.divRelationshipRetiredTarget)
    this.#showDivIfTrue(this.btnRelationshipVolunteerTarget.checked, this.divRelationshipVolunteerTarget)

    if (this.btnRelationshipVolunteerTarget.checked) {
      this.btnIAgreeTarget.checked = false
    }

    this.#showOrHideBtnIAgree()
  }

  btnIAgree() {
    this.#showDivIfTrue(this.btnEmailYesTarget.checked || this.btnIAgreeTarget.checked, this.divEmailYesTarget)
  }

  #showOrHideBtnIAgree() {
    const student = this.btnRelationshipStudentTarget.checked
    const contractor = this.btnRelationshipContractorTarget.checked
    const retired = this.btnRelationshipRetiredTarget.checked
    if (student || contractor || retired) {
      this.divIAgreeTarget.classList.remove('d-none')
    } else {
      this.divIAgreeTarget.classList.add('d-none')
    }
    this.#showDivIfTrue(this.btnEmailYesTarget.checked || this.btnIAgreeTarget.checked, this.divEmailYesTarget)
  }

  #showDivIfTrue(boolean, div) {
    if (boolean) {
      div.classList.remove('d-none')
    } else {
      div.classList.add('d-none')
    }
  }
}
